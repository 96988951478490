import { sum } from "lodash";

// TODO: To make OpenAPI work
export type BigIntString = any;

// `BigInt` is a builtin type, so using `BigIntString`
// as the number is stored in DB as a string
export const BigIntString = {
  toBigIntString(value: number): BigIntString {
    // removes fraction from a number. So 123.1234 will be transformed into 123
    return Math.trunc(value).toString() as unknown as BigIntString;
  },

  fromBigIntString(bigIntString: BigIntString): number {
    return Number(bigIntString);
  },

  equals(num1: BigIntString | number, num2: BigIntString | number) {
    return Number(num1) === Number(num2);
  },

  sum(values: BigIntString[]) {
    return sum(values.map(v => Number(v))).toString() as unknown as BigIntString;
  },

  difference(values: BigIntString[]) {
    if (values.length === 0) {
      return BigIntString.toBigIntString(0);
    }
    return values
      .map(v => Number(v))
      .reduce((acc, v) => acc - v)
      .toString() as unknown as BigIntString;
  },
};
